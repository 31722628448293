<template>
  <div class="container">
    <div class="container">
      <char-creation-options
        @close="showBuyForOption = ''"
        v-if="showBuyForOption"
        :option_to_buy="showBuyForOption"
        :backButton="true"
        :blur-background="false"
      >
      </char-creation-options>
      <hr-section
        >{{
          ai_id == null
            ? "Add as a friend!"
            : `Modify AI: ${this.initial_nickname}`
        }}
        &nbsp;🤗</hr-section
      >
      <div v-if="!ai_id">
        Having a great time with this AI? 🪩<br />Add them as a friend <br />and
        chat again in the future 🍻<br />They'll remember previous chats! 👏
      </div>
      <div class="form-group">
        <label for="nickname"><strong>Nickname</strong>&nbsp;🏷️</label>
        <input
          type="text"
          id="nickname"
          name="nickname"
          placeholder="Enter AI nickname"
          v-model="nickname"
        />
      </div>
      <div class="desc-instr">
        <div class="form-group">
          <label for="description"><strong>Description</strong>&nbsp;✏️</label>
          <textarea
            id="description"
            name="description"
            placeholder="(Optional) Enter a description, *only visible to you*"
            v-model="description"
          ></textarea>
        </div>
        <div class="form-group">
          <label for="custom_instructions"
            ><strong>Custom Instructions</strong>&nbsp;✏️</label
          >
          <textarea
            id="custom_instructions"
            name="custom_instructions"
            placeholder="(Optional) Additional instructions for the AI. Write in the first person as yourself, for example: 'My name is... I am... We know each other from... You are..."
            v-model="custom_instructions"
          ></textarea>
        </div>
      </div>
      <base-button
        :icon="'fa-solid fa-floppy-disk'"
        :icon-position="'left'"
        :action="saveCurrentAI"
        :when-to-shrink="0"
        class="save"
        >&nbsp;&nbsp;Save</base-button
      >
    </div>
    <div v-if="thisOutputMessage != ''" v-html="thisOutputMessage"></div>
  </div>
</template>
<script>
import CharCreationOptions from "./paying_components/CharCreationOptions.vue";
import BaseButton from "./BaseButton.vue";
import HrSection from "./HrSection.vue";
import { useUserStore } from "@/store/userStore";
import { auth } from "@/services/firebase";

export default {
  components: {
    BaseButton,
    HrSection,
    CharCreationOptions,
  },
  props: {
    nicknameText: { type: String, default: "" },
    descriptionText: { type: String, default: "" },
    selectedAiId: { type: String, default: null },
    customInstructions: { type: String, default: null },
  },
  data() {
    return {
      showBuyForOption: "",
      ai_id: this.selectedAiId,
      nickname: this.nicknameText,
      description: this.descriptionText,
      custom_instructions: this.customInstructions,
      original_custom_instructions: this.customInstructions,
      initial_nickname: this.nicknameText,
      userStore: useUserStore(),
      thisOutputMessage: "",
    };
  },
  create() {},
  methods: {
    emitClose(status, remaining_slots = null, tot_slots = null) {
      this.$emit("close", { status, remaining_slots, tot_slots });
    },
    async saveCurrentAI() {
      const nickname = this.nickname.trim();
      if (nickname == "") {
        this.thisOutputMessage = "❌Nickname can't be empty!❌";
      } else if (
        this.ai_id == null &&
        this.userStore.saved_ai_personas.some(
          (persona) => persona.nickname === nickname
        )
      ) {
        this.thisOutputMessage =
          "❌Nickname already used!❌<br>Please use a different nickname";
        this.nickname = "";
      } else if (
        this.custom_instructions != this.original_custom_instructions &&
        !this.userStore.options_unlocked["custom_instructions"]
      ) {
        this.showBuyForOption = "custom_instructions";
      } else {
        const description = this.description.trim();
        const token = await auth.currentUser.getIdToken(true);

        const response = await fetch(`${process.env.VUE_APP_URL}/api/save_ai`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ai_id: this.ai_id,
            nickname: nickname,
            description: description,
            custom_instructions: this.custom_instructions,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data["response"] == "already_there") {
            this.thisOutputMessage = "AI already saved";
          }
          if (data["response"] == "nickname_already_used") {
            this.thisOutputMessage = "Nickname already used";
          }
          if (data["response"] == "no_agent") {
            this.thisOutputMessage = "No Agent Loaded";
          }
          if (data["response"] == "no_space") {
            this.emitClose(
              "no_space",
              data["remaining_slots"],
              data["tot_slots"]
            );
          }
          if (data["response"] == "ok") {
            await this.userStore.fetchSavedPersonas();
            this.emitClose(
              "success",
              data["remaining_slots"],
              data["tot_slots"]
            );
          }
        }
      }
      setTimeout(() => {
        this.thisOutputMessage = "";
      }, 2000);
    },
  },
};
</script>
<style scoped>
.save-ai-btn {
  z-index: 5;
}

.container {
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 1.5dvw, 20px);
  align-items: center;
  width: 100%;
}

.save {
  margin-top: 10px;
}
.desc-instr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.save,
.close {
  align-self: center;
}

.form-container {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
textarea {
  height: 100px;
}
</style>
