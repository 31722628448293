// src/stores/aggregatesStore.js
import { defineStore } from "pinia";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export const useAggregatesStore = defineStore("aggregates", {
  state: () => ({
    numAIgenerated: 0,
  }),
  actions: {
    // subscribeToNumAIgenerated() {
    //   const db = getFirestore();
    //   const aggregateRef = doc(db, "aggregates", "numAIgenerated");

    //   // Setting up the real-time listener
    //   const unsubscribe = onSnapshot(aggregateRef, (doc) => {
    //     if (doc.exists()) {
    //       this.numAIgenerated = doc.data().total;
    //     } else {
    //       console.error("No aggregate data found");
    //     }
    //   });

    //   // Return the unsubscribe function to call when the component unmounts
    //   return unsubscribe;
    // },
    async fetchNumAIgenerated() {
      const db = getFirestore();
      const aggregateRef = doc(db, "aggregates", "numAIgenerated");

      try {
        const docSnap = await getDoc(aggregateRef);
        if (docSnap.exists()) {
          this.numAIgenerated = docSnap.data().total;
        } else {
          console.error("No data found for numAIgenerated");
          this.numAIgenerated = 0; // Optionally reset the state if not found
        }
      } catch (error) {
        console.error("Error fetching numAIgenerated:", error);
        this.numAIgenerated = 0; // Handle errors or reset state if necessary
      }
    },
  },
});
