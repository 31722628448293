<template>
  <div class="toggle-wrapper">
    <input type="checkbox" id="toggle" :checked="isToggled" @change="toggle" />
    <label for="toggle" class="toggle-switch"></label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isToggled: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled;
    },
  },
};
</script>
<style scoped>
/* Same CSS as before */
.toggle-wrapper {
  position: relative;
  width: 50px;
  height: 25px;
}

input[type="checkbox"] {
  display: none;
}

.toggle-switch {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 25px;
  transition: background-color 0.3s;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: left 0.3s;
}

input[type="checkbox"]:checked + .toggle-switch {
  background-color: #4caf50;
}

input[type="checkbox"]:checked + .toggle-switch::before {
  left: 25px;
}
</style>
