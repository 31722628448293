<template>
  <panel-message class="welcome-screen">
    <div class="top-bit">
      <h1 class="text-mid-big text-center">
        Welcome to
        <main-logo
          class="main-logo"
          :from-on-click="false"
          :show-face="false"
          :shrink="false"
          v-if="domainInfo.name != 'PRERENDER'"
        />
      </h1>
      <div>
        <h3 style="margin: 10px">The Most Realistic AI Chat Ever Made!</h3>
        <img
          src="@/assets/logo_no_outline.png"
          alt="Logo"
          class="logo"
          id="logo"
          v-if="domainInfo.name != 'OmegleAI'"
        />
      </div>
    </div>
    <div>
      <span class="btn-next" @click="$emit('close')"
        ><strong>Chat Now!</strong></span
      >

      <div
        style="margin-top: 20px; cursor: pointer"
        @click="showUpdates = !showUpdates"
      >
        <i
          >Most Recent Major Update: <b>{{ updatesList[0].date }}</b></i
        >
        &nbsp;<span
          :class="['fas', showUpdates ? 'fa-caret-down' : 'fa-caret-right']"
        ></span>
        <transition name="fade" appear mode="out-in">
          <div v-if="showUpdates" class="updates-container">
            <div class="updates">
              These are only the major updates. Small fixes are made every day!
              <div
                class="one-update-field"
                v-for="(update, index) in this.inprogressList"
                :key="index"
              >
                <span
                  class="date-update"
                  style="font-weight: bold; color: var(--accent-color)"
                  >{{ update.date }}</span
                >
                <span class="text-update">{{ update.description }}</span>
              </div>
              <div
                class="one-update-field"
                v-for="(update, index) in this.updatesList"
                :key="index"
              >
                <span
                  class="date-update"
                  style="font-weight: bold; color: #00df00"
                  >• {{ update.date }}</span
                >
                <span class="text-update">{{ update.description }}</span>
              </div>
            </div>
            <div style="font-size: 0.8rem">
              For the latest updates and development news, join us on
            </div>
            <base-button
              class="discord-btn social-btn"
              :icon="'fa-brands fa-discord'"
              @click="openDiscordLink"
              :icon-position="'left'"
              :when-to-shrink="0"
              :small-button="true"
            >
              &nbsp;Discord</base-button
            >
            <div class="join-discord"></div>
          </div>
        </transition>
      </div>
    </div>
    <noscript>
      <strong style="color: red"
        >We're sorry but this site doesn't work properly without JavaScript
        enabled. Please enable it to continue.</strong
      >
    </noscript>
    <div class="main-text">
      Forget about predictable exchanges with unrealistic chatbots. Here, you'll
      chat with lifelike AIs, each with unique hobbies, interests, quirks, and
      conversation styles. Whether you want to talk to a completely random AI
      (think Omegle, but without the creeps) or create one tailored to you,
      we've got you covered.<br />If you're seeking advice, looking to unwind,
      or just need someone to listen, your perfect AI partner is ready for you.
      Experience the future of digital companionship with our cutting-edge AI
      friend technology 🤗
    </div>

    <social-big-btns />
    <div class="sub-container">
      <div class="sub-cont-title">&nbsp;Main Features!&nbsp;</div>

      <div class="custom-bullets">
        <div class="point">
          <div class="icon">💬&nbsp;&nbsp;</div>
          <div>
            Immerse yourself in <strong>lifelike conversations</strong> - it'll
            feel just like chatting with a real person!
          </div>
        </div>
        <div class="point discover">
          <div class="icon" style="margin-top: 5px">✨&nbsp;&nbsp;</div>
          <div>
            Every
            <span class="fake-btn"
              ><i class="fa-solid fa-refresh"></i>&nbsp;<strong
                >New</strong
              ></span
            >
            chat brings a <strong>one-of-a-kind AI</strong> with unique
            personality, interests, and hobbies!
          </div>
        </div>
        <div class="custom-bullets">
          <div class="point discover">
            <div class="icon" style="margin-top: 5px">🎨&nbsp;&nbsp;</div>
            <div>
              <span class="fake-btn"
                ><i class="fa-solid fa-heart"></i>&nbsp;<strong
                  >Create</strong
                ></span
              >
              your ideal chatting companion!
              <strong
                >Customize their look, mood, personality, and even romantic
                preferences!🌶️😉</strong
              >
            </div>
          </div>
        </div>
        <div class="point manager">
          <div class="icon">👥&nbsp;&nbsp;</div>

          <div>
            Chat with a random stranger🥸, your crush😍, or even your
            daughter👧! Choose from <strong>various roles</strong>, with more
            being added all the time!
          </div>
        </div>

        <div class="point save">
          <div class="icon" style="margin-top: 5px">💾&nbsp;&nbsp;</div>

          <div>
            Found someone you clicked with? Add them to your
            <span class="fake-btn"
              ><i class="fa-solid fa-smile"></i>&nbsp;<strong
                >AI friends</strong
              ></span
            >
            panel - they'll remember you and your past conversations! 🧠
          </div>
        </div>
        <div class="point free">
          <div class="icon">🌍&nbsp;&nbsp;</div>

          <div>
            Chat in <strong>over 20 different languages</strong> with AIs from
            all over the world!
          </div>
        </div>
      </div>
    </div>
    <div>
      <strong>
        ⚠️ All chats are completely private and secure. We do not access or
        monitor them!
      </strong>
    </div>
    <p class="text-mid-big"><strong>Have fun😄!</strong><br /></p>
    <span class="btn-next" @click="$emit('close')"
      ><strong>Chat Now!</strong></span
    >
    <div>
      <span class="terms-and-pp-text"
        >By continuing, you agree to our
        <span @click="showTerms = true" class="privacy-policy-link">Terms</span>
        and acknowledge our
        <span @click="showPP = true" class="privacy-policy-link"
          >Privacy Policy</span
        >
        <br />All chats are completely private and secure. We do not access or
        monitor them. Go wild! 😜
      </span>
    </div>
    <privacy-policy v-if="showPP" @close="showPP = false"></privacy-policy>
    <terms-service v-if="showTerms" @close="showTerms = false"></terms-service>
  </panel-message>
</template>

<script>
import SocialBigBtns from "./SocialBigBtns.vue";
import MainLogo from "./MainLogo.vue";
import PanelMessage from "./PanelMessage.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import TermsService from "./TermsService.vue";
import BaseButton from "./BaseButton.vue";
export default {
  data() {
    return {
      inprogressList: [
        {
          date: "~ in progress ~",
          description: "Improved Image System",
        },
      ],
      updatesList: [
        {
          date: "29 Oct 24",
          description: "Automatic reconnection, better mobile experience",
        },
        {
          date: "1 Oct 24",
          description: "Custom instructions added",
        },
        {
          date: "6 Sept 24",
          description: "Profile pictures available",
        },
        {
          date: "30 Aug 24",
          description: "Add AI as friend, share AI, improved memory",
        },
        {
          date: "1 Aug 24",
          description: "Launch day! 🎉",
        },
      ],
      showUpdates: false,
      showPP: false,
      showTerms: false,
    };
  },
  components: {
    BaseButton,
    SocialBigBtns,
    PanelMessage,
    TermsService,
    PrivacyPolicy,
    MainLogo,
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  methods: {
    openDiscordLink(event) {
      event.stopPropagation();
      window.open(
        "https://discord.gg/6kg5XMYtjS",
        "_blank",
        "noopener,noreferrer"
      );
    },
  },
};
</script>

<style scoped>
.top-bit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(10px, 1dvw, 20px);
}
.welcome-screen > ::v-deep .standard-content {
  --max-width: 65dvw;
  gap: 40px;
}
.sub-container {
  padding: 30px;
}

@media (max-width: 600px) {
  .sub-container {
    padding: 10px;
  }
}
.main-text {
  text-align: justify;
  line-height: 1.5rem;
}
.point {
  display: flex;
  align-items: flex-start;
}
.custom-bullets {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 1.8dvh, 30px);
}

.text-welcome {
  align-self: center;
  text-align: center;
  text-justify: inter-word;
  hyphens: auto;
}

.fake-btn {
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  white-space: nowrap;
  display: inline-flex;
  align-items: center;

  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  vertical-align: baseline;
  line-height: inherit;
}
.date-update {
  white-space: nowrap;
}
.join-discord {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.text-update {
  text-align: right;
}
.one-update-field {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  font-size: 0.8rem;
}
.updates-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.updates {
  font-size: 0.8rem;
  max-height: 20dvw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.icon {
  transition: transform 0.3s ease;
  /* margin-top: 5px; */
}
.icon:hover {
  transform: scale(1.1);
}
.social-btn ::v-deep .base-button {
  /* max-width: 35px; */
  color: white;
}
.discord-btn {
  align-self: center;
}
.discord-btn ::v-deep .base-button {
  background-color: var(--discord-blue);
}
.social-btn:hover ::v-deep .base-button,
.social-btn:focus ::v-deep .base-button {
  transform: scale(1.1);
  color: #ffffff;
  text-decoration: none;
}

.discord-btn:hover ::v-deep .base-button,
.discord-btn:focus ::v-deep .base-button {
  background-color: white;
  color: var(--discord-blue);
}
/* .manager {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
} */

.friends-btn {
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
}
.people-icon {
  object-fit: contain;
  display: block;
  width: 35px;
}

.privacy-policy-link {
  color: rgb(39, 39, 47);
  cursor: pointer;
  text-decoration: underline;
}

.privacy-policy-link:hover {
  text-decoration: none;
}

.terms-and-pp-text {
  font-size: clamp(0.8rem, 0.9dvw, 1rem);
}

.terms-and-pp-text span {
  font-size: clamp(0.8rem, 0.9dvw, 1rem);
}
.logo {
  height: clamp(2.5rem, 4dvw, 3.5rem);
  transform: translateX(1px) translateY(5px) scaleX(-1);
  filter: drop-shadow(-1px 1px 2px rgba(0, 0, 0, 0.6));
  position: relative;
  transition: transform 0.3s ease, filter 0.3s ease-in-out;
  cursor: pointer;
}

.btn-next {
  background-color: initial;
  border-color: rgb(50, 52, 53);
  background-image: linear-gradient(
    60deg,
    #00bfff,
    #00bfff,
    #00bfff,
    #66c7d7,
    #ffc06a,
    #ffb140,
    #ffa726,
    #f3df00,
    #f3df00,
    #f3df00,
    #f3df00
  );
  background-size: 400% 300%;
  -webkit-animation: animatedgradient 6s ease infinite alternate;
  animation: animatedgradient 6s ease infinite alternate;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 10px;
  padding-top: clamp(0rem, 1.2dvh, 5rem);
  padding-bottom: clamp(0rem, 1.5dvh, 5rem);
  padding-right: clamp(0rem, 3.5dvw, 5rem);
  padding-left: clamp(0rem, 3.5dvw, 5rem);
  transition: background-color 0.5s ease, transform 0.3s ease, color 1.5s ease,
    box-shadow 0.3s ease, border 0.3s ease;
  color: white;
  cursor: pointer;
  font-size: clamp(1rem, 5dvw, 2rem);
}
.btn-next:hover {
  transform: scale(1.2);
  color: black;
}
.logo {
  cursor: default;
}

.main-logo {
  cursor: default;
  transform: scale(1.3);
  transition: transform 0.5s ease, filter 1s ease;
  filter: drop-shadow(-1px 1px 2px rgb(255, 232, 104));
}

.main-logo:hover {
  transform: scale(1.6);
  filter: drop-shadow(-1px 3px 5px rgb(255, 217, 0));
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h3 {
  font-size: clamp(1.5rem, 2dvw, 4.5rem);
  margin-top: 0;
}
</style>
