<template>
  <panel-message
    @close="$emit('close')"
    :close-on-click-out="true"
    :back-button="true"
  >
    <hr-section
      v-if="!openAIformNew && !openAIformModify"
      class="panel-title"
      style="margin-bottom: 20px"
      ><span class="panel-title text-mid-big"
        >The AI
        {{ domainInfo.name == "OmegleAI" ? "Friends" : "Peeps" }}
        Panel</span
      ></hr-section
    >

    <preference-panel
      v-if="showPreferences"
      :backButton="true"
      @close="showPreferences = false"
      :nextSessMessage="true"
    />
    <base-button
      v-if="!openAIformNew && !openAIformModify"
      :icon="'fa-solid fa-floppy-disk'"
      :icon-position="'left'"
      :when-to-shrink="0"
      @click="checkOpenAIformSaveNew"
      class="save-ai-btn"
    >
      &nbsp;&nbsp;Add as a Friend!
    </base-button>
    <base-button
      v-if="!intentLoadAI && !openAIformNew && !openAIformModify"
      icon="fa-solid fa-bars-progress"
      :icon-position="'left'"
      :when-to-shrink="0"
      @click="intentLoadAI = true"
      class="save-ai-btn"
    >
      &nbsp;&nbsp;Load AI
    </base-button>
    <div
      v-if="intentLoadAI && !openAIformNew && !openAIformModify"
      class="load-id"
    >
      <input type="text" placeholder="Enter AI ID" v-model="loaded_ai_id" />
      <base-button
        icon="fa-solid fa-bars-progress"
        :icon-position="'left'"
        :when-to-shrink="0"
        :action="() => chatWithAI(loaded_ai_id)"
        :use-confirm-dialog="true"
        :confirm-message="'This will interrupt the current conversation and start a new one with the Loaded AI.<br>Are you sure?'"
        class="save-ai-btn"
      >
        &nbsp;&nbsp;Load and Chat
      </base-button>
    </div>
    <div v-if="outputMessage != ''" v-html="outputMessage"></div>
    <save-ai
      @close="handleCloseSaveAI"
      :customInstructions="userStore.ai_custom_instructions_info"
      :nickname-text="userStore.current_agent_chat_name"
      v-if="openAIformNew"
    ></save-ai>
    <save-ai
      @close="handleCloseSaveAI"
      :nickname-text="userStore.saved_ai_personas[show_info_index].nickname"
      :description-text="
        userStore.saved_ai_personas[show_info_index].description
      "
      :selected-ai-id="userStore.saved_ai_personas[show_info_index].persona_id"
      :custom-instructions="
        userStore.saved_ai_personas[show_info_index].custom_instructions
      "
      v-if="openAIformModify"
    ></save-ai>
    <div v-if="!openAIformNew && !openAIformModify" class="standard-content">
      <hr-section class="urpeep-title"
        ><span class="many-emojis">👩🏿‍💼👨🏼‍🌾👨🏽‍🔧</span>
        <span class="few-emojis hidden">👨🏽‍🔧</span>
        Your AI
        {{ domainInfo.name == "OmegleAI" ? "Friends" : "Peeps" }}
        <span class="many-emojis">👩🏻‍🚒👨🏾‍🔬👨🏿‍🍳</span>
        <span class="few-emojis hidden">👩🏻‍🚒</span></hr-section
      >
      <div
        v-for="(sp, index) in userStore.saved_ai_personas"
        :key="index"
        class="ai-container"
        :class="{ 'selected-container': show_info_index == index }"
      >
        <div
          v-if="show_info_index != index"
          @click="toggleDetails(index)"
          class="click-title only-title"
          :class="{
            current_ai: sp.persona_id === userStore.current_agent_id,
          }"
        >
          <div class="circle-image-small">
            <img :src="sp.image_url" alt="Your Image" />
          </div>
          <span v-if="sp.persona_id == userStore.current_agent_id"
            >🗨️&nbsp;</span
          >
          {{ sp.nickname }}
          <i class="fa-solid fa-caret-right"></i>
        </div>
        <div v-if="show_info_index == index" class="sub-container">
          <div
            class="sub-cont-title click-title"
            @click="toggleDetails(index)"
            :class="{
              current_ai: sp.persona_id === userStore.current_agent_id,
            }"
          >
            &nbsp;<span v-if="sp.persona_id == userStore.current_agent_id"
              >🗨️&nbsp;</span
            >{{ sp.nickname }}&nbsp;<i class="fa-solid fa-caret-down">&nbsp;</i>
          </div>
          <div class="circle-image">
            <img :src="sp.image_url" alt="Your Image" />
          </div>
          <div
            class="text-small"
            style="text-align: left; align-self: flex-end"
            v-if="sp.persona_id == userStore.current_agent_id"
          >
            <strong>Currently Chatting With You</strong>
          </div>
          <!-- <div v-if="sp.persona_id == userStore.current_agent_id"></div> -->
          <div>{{ sp.description }}</div>
          <div class="text-custom-instr" v-if="sp.custom_instructions">
            <i> "{{ sp.custom_instructions }}"</i>
          </div>
          <div class="btn-container">
            <base-button
              :is-disabled="sp.persona_id == userStore.current_agent_id"
              class="small-btn"
              :action="() => chatWithAI(sp.persona_id)"
              :icon="'fa-regular fa-comment'"
              :icon-position="'right'"
              perform-action-text="<i class='fa-solid fa-check'></i> Chat!"
              :when-to-shrink="0"
              :class="{
                'chat-btn-sel-index':
                  sp.persona_id != userStore.current_agent_id,
              }"
              :use-confirm-dialog="true"
              :confirm-message="'This will interrupt the current conversation.<br>Are you sure?'"
              ><div class="btn-text">Chat&nbsp;</div></base-button
            >
            <base-button
              class="small-btn"
              :icon="'fa-solid fa-eraser'"
              :icon-position="'right'"
              :action="() => eraseHistoryAI(sp.persona_id)"
              :use-confirm-dialog="true"
              :confirm-message="
                'This will erase the memory of your chat with this AI<br>The AI personality is going to be unaffected.' +
                (sp.persona_id == userStore.current_agent_id
                  ? '<br><strong>Upon erasing, your current chat with this AI will start over!</strong>'
                  : '') +
                '<br>Are you sure?'
              "
              :when-to-shrink="0"
            >
              <div class="btn-text">Erase History&nbsp;</div>
            </base-button>
            <base-button
              class="small-btn"
              icon="fa-solid fa-share-from-square"
              :icon-position="'right'"
              :action="copyPersonaIDclipboard"
              :use-confirm-dialog="true"
              perform-action-text="<i class='fa-regular fa-copy'></i> Copy on Clipboard"
              :confirm-message="`This is the AI personality ID:<br><strong>${userStore.saved_ai_personas[show_info_index].persona_id}</strong><br>Feel free to share it with anyone👐<br>To use a AI personality ID, click on the <strong>'Load AI'</strong> button and paste it into the field that appears.<br><br><strong>⚠️Note⚠️</strong><br>The AI will <strong>NOT</strong> share your chat history with other people🙃`"
              :when-to-shrink="0"
            >
              <div class="btn-text">Share&nbsp;</div>
            </base-button>
            <base-button
              class="small-btn"
              :icon="'fa-solid fa-pen'"
              :icon-position="'right'"
              @click="openAIformModify = true"
              :when-to-shrink="0"
            >
              <div class="btn-text">Modify&nbsp;</div>
            </base-button>
            <base-button
              class="small-btn"
              :icon="'fa-solid fa-trash'"
              :icon-position="'right'"
              :action="() => deleteSavedAI(sp)"
              :use-confirm-dialog="true"
              :confirm-message="'This will remove this AI from your saved AI peeps.<br>If no one else is using this AI, it will be permanently erased.<br>Are you sure?'"
              :when-to-shrink="0"
            >
              <div class="btn-text">Delete&nbsp;</div>
            </base-button>
          </div>
          <div
            v-if="outputMessageSelAI != ''"
            v-html="outputMessageSelAI"
          ></div>
        </div>
      </div>
    </div>
  </panel-message>
</template>

<script>
import PreferencePanel from "./PreferencePanel.vue";
import HrSection from "./HrSection.vue";
import SaveAi from "./SaveAi.vue";
import PanelMessage from "./PanelMessage.vue";
import BaseButton from "./BaseButton.vue";
import { useUserStore } from "@/store/userStore";
import { useChatStore } from "@/store/chatStore";
import { auth } from "@/services/firebase";
export default {
  components: {
    BaseButton,
    PanelMessage,
    HrSection,
    SaveAi,
    PreferencePanel,
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  mounted() {
    this.show_info_index = this.userStore.saved_ai_personas.findIndex(
      (item) => {
        return item.persona_id === this.userStore.current_agent_id;
      }
    );
  },
  data() {
    return {
      userStore: useUserStore(),
      buttonText: "Share On Facebook  ",
      successShare: false,
      openAIformNew: false,
      openAIformModify: false,
      show_info_index: 0,
      loadAItext: "Load AI",
      outputMessage: "",
      intentLoadAI: false,
      loaded_ai_id: "",
      showPreferences: false,
      outputMessageSelAI: "",
    };
  },
  methods: {
    handleCloseSaveAI(data) {
      const status = data.status;
      if (status == "success") {
        if (this.openAIformNew) {
          this.outputMessage = `AI Saved! ✅<br><strong>Remaining Slots: ${data.remaining_slots}</strong>`;

          setTimeout(() => {
            this.outputMessage = "";
          }, 5000);
        }
        if (this.openAIformModify) {
          this.outputMessageSelAI = "AI Modified! ✅";
          setTimeout(() => {
            this.outputMessageSelAI = "";
          }, 3000);
        }
      }
      if (status == "no_space") {
        if (this.openAIformNew) {
          this.outputMessage = `❌<span style="color:red">AI Couldn't be Saved!</span>❌<br>All <strong> ${data.tot_slots}</strong> AI slots used!<br>Delete some of yours AI peeps if you want to save new ones!`;
          setTimeout(() => {
            this.outputMessage = "";
          }, 5000);
        }
      }
      this.openAIformModify = false;
      this.openAIformNew = false;
    },
    async copyPersonaIDclipboard() {
      await navigator.clipboard.writeText(
        this.userStore.saved_ai_personas[this.show_info_index].persona_id
      );
      this.outputMessageSelAI = "ID copied ✅";
      setTimeout(() => {
        this.outputMessageSelAI = "";
      }, 3000);
    },

    async eraseHistoryAI(ai_id) {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(
        `${process.env.VUE_APP_URL}/api/erase_history`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ai_id: ai_id,
          }),
        }
      );
      this.outputMessage = "History Deleted! ✅";
      setTimeout(() => {
        this.outputMessage = "";
      }, 3000);
      if (response.ok) {
        await response.json();
        if (this.userStore.current_agent_id == ai_id) {
          useChatStore().newSessionWithSpecificAI(ai_id, false);
          this.$emit("close");
        }
      }
    },
    chatWithAI(ai_id) {
      useChatStore().newSessionWithSpecificAI(ai_id);
      this.$emit("close");
    },

    async deleteSavedAI(selected_persona) {
      const token = await auth.currentUser.getIdToken(true);
      await fetch(`${process.env.VUE_APP_URL}/api/delete_ai_persona`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ai_id: selected_persona.persona_id,
        }),
      });
      this.outputMessage = "AI Deleted ✅";
      setTimeout(() => {
        this.outputMessage = "";
      }, 3000);

      this.userStore.fetchSavedPersonas();
    },
    checkOpenAIformSaveNew() {
      if (this.userStore.currentIsSaved) {
        this.outputMessage = "❌Current AI is already saved!❌";
        setTimeout(() => {
          this.outputMessage = "";
        }, 2000);
      } else {
        this.openAIformNew = true;
      }
    },

    toggleDetails(index) {
      if (this.show_info_index == index) {
        this.show_info_index = -1;
      } else {
        this.show_info_index = index;
      }
    },
  },
};
</script>

<style scoped>
.selected-container {
  width: 100%;
}
.ai-container {
  align-self: flex-start;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.current_ai {
  font-weight: bold;
  color: green;
}
.circle-image img {
  width: 150px; /* Set the desired size */
  height: 150px;
  border-radius: 50%; /* Make it a circle */
  object-fit: cover; /* Ensure the image covers the entire area */
  display: block; /* Remove extra space */
}
.circle-image-small img {
  width: 40px; /* Set the desired size */
  height: 40px;
  border-radius: 50%; /* Make it a circle */
  object-fit: cover; /* Ensure the image covers the entire area */
  display: block; /* Remove extra space */
}
.list {
  list-style-type: none;
  padding-left: 10px;
  margin: 5px;
}
.panel-title ::v-deep .title {
  top: -17px;
}
.text-custom-instr {
  text-align: center;
}
.btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: clamp(2px, 2dvw, 10px);
  max-width: 400px;
}
.click-title {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-align: left;
  align-self: flex-start;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, border 0.3s ease;
  /* width: 100%; */
}
.click-title:hover {
  transform: scale(1.05);
  color: var(--interactive-element-color);
}
.only-title {
  /* align-self: flex-start; */
  width: 100%;
  margin-left: 15px;
}

.small-btn {
  flex: 1 1 30%;
}
::v-deep .small-btn > .base-button {
  padding: clamp(0rem, 1dvh, 1rem);
  width: 100%;
  height: 100%;
  /* background-color: #ffa726; */
}
.close {
  margin-top: 20px;
}

.load-id {
  display: flex;
  gap: clamp(10px, 1dvh, 20px);
}
.standard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(10px, 1dvw, 20px);
}
input[type="text"],
textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.urpeep-title {
  max-width: 400px;
  min-width: 30dvw;
}

.few-emojis {
  display: none;
}
@media (max-width: 820px) {
  .urpeep-title {
    min-width: 50dvw;
  }
}
@media (max-width: 620px) {
  .many-emojis {
    display: none;
  }
  .few-emojis {
    display: inline;
  }
  .urpeep-title {
    min-width: 50dvw;
  }
  .btn-text {
    font-size: clamp(0.8rem, 3.5dvw, 1rem);
  }
  /* .btn-container {
    width: 100%;
  } */
}
.chat-btn-sel-index ::v-deep .main-button {
  /* background-image: linear-gradient(to right, #ffa726, #ffef26); */
  background-color: #ffd926;
  /* border: 2px solid black; */
}
.chat-btn-sel-index ::v-deep .main-button:hover {
  background-color: var(--interactive-element-color);
}

@media (max-width: 500px) {
  .urpeep-title {
    min-width: 70dvw;
  }
  /* .few-emojis {
    display: none;
  } */
}
</style>
