d
<template>
  <panel-message>
    <h1 class="text-mid-big text-center">It's great to see you joining! 👋</h1>
    <div class="text-mid">
      <strong>As a logged-in user you can:</strong>
    </div>
    <!-- <ul class="custom-bullets"> -->
    <!-- <li class="discover"> -->
    <div class="custom-bullets">
      <div class="custom-bullets">
        <div class="sub-container">
          <div class="sub-cont-title text-mid-big">&nbsp;🛠️&nbsp;</div>
          <div class="point discover">
            <!-- <div class="icon">🛠️&nbsp;&nbsp;</div> -->
            <div>
              <span class="fake-btn"
                ><i class="fa-solid fa-heart"></i>&nbsp;<strong
                  >Create
                </strong></span
              >
              your perfect chatting partner! Customize their appearance, mood,
              personality, and even sexual preferences!
            </div>
          </div>
        </div>
        <div class="sub-container">
          <div class="sub-cont-title text-mid-big">&nbsp;🗂️&nbsp;</div>
          <div class="point manager">
            <!-- <div class="icon" style="margin-top: 4px">🗂️&nbsp;&nbsp;</div> -->
            <div>
              Manage your AI friends in your
              <strong>AI friends panel&nbsp;</strong>
              <span class="friends-btn">
                <img
                  class="people-icon"
                  src="@/assets/g6.svg"
                  alt="Custom Icon"
                /> </span
              >. In there you can
              <span class="fake-btn"
                ><i class="fa-solid fa-save"></i>&nbsp;<strong
                  >Save
                </strong></span
              >
              the AIs you like so you can contact them on a different time
              (they'll remember you previous chats!). You can also<span
                class="fake-btn"
                ><i class="fa-solid fa-share-from-square"></i>&nbsp;<strong
                  >Share
                </strong></span
              >
              your AIs with anyone!
            </div>
          </div>
        </div>
        <div class="sub-container">
          <div class="sub-cont-title text-mid-big">&nbsp;🎉&nbsp;</div>
          <div class="point free">
            <!-- <div class="icon" style="margin-bottom: 10px">🎉&nbsp;&nbsp;</div> -->

            <div style="display: flex; flex-direction: column; gap: 10px">
              <!-- <li class="free"> -->
              <div>
                Enjoy your <strong>trial chatting</strong> time, plus rewards
                when you engage on our socials!
              </div>
              <social-big-btns style="align-self: center" />
              <div>
                And if you need more time, check your options in your Profile
                <span style="white-space: nowrap"
                  >panel
                  <span class="friends-btn"><i class="fas fa-user"></i></span
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <strong>
        All chats are completely private and secure. We do not access or monitor
        them!
      </strong>
    </div>
    <p class="text-mid-big"><strong>Have fun😄!</strong><br /></p>
    <base-button class="btn-next" @click="$emit('close')">Will do!</base-button>
  </panel-message>
</template>

<script>
import SocialBigBtns from "./SocialBigBtns.vue";
import BaseButton from "./BaseButton.vue";
import PanelMessage from "./PanelMessage.vue";
export default {
  components: {
    BaseButton,
    PanelMessage,
    SocialBigBtns,
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
};
</script>

<style scoped>
.point {
  display: flex;
  align-items: center;
}
.custom-bullets {
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 2dvh, 20px);
}

.text-welcome {
  align-self: center;
  text-align: center;
  text-justify: inter-word;
  hyphens: auto;
}

.fake-btn {
  /* Use a background image for the button */
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  white-space: nowrap;
  display: inline-flex;
  align-items: center;

  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  vertical-align: baseline;
  line-height: inherit;
}
.fas {
  font-size: clamp(0.8rem, 1.2dvw, 1.5rem);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1; /* Ensures the icon is vertically centered */
}
/* .manager {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
} */

.friends-btn {
  background-image: radial-gradient(
    ellipse at center,
    #ffa826c9,
    rgba(255, 255, 255, 0)
  );
  width: clamp(20px, 3.5dvw, 40px);
  height: clamp(20px, 3.5dvw, 40px);
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.people-icon {
  width: clamp(20px, 3.2dvw, 35px);
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
