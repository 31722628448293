<template>
  <div class="message-form">
    <div class="form-fields">
      <div>Drop us a message! 👋</div>
      <textarea
        class="text-small"
        v-model="message"
        placeholder="Type your message..."
      ></textarea>
      <input
        type="text"
        id="email"
        class="text-very-small"
        name="email"
        placeholder="E-mail (needed if you want a response!)"
        v-model="email"
      />

      <div class="form-btns">
        <base-button :action="() => sendMessage()">Send</base-button>
        <base-button type="button" @click="this.$emit('close')"
          >Close</base-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import { useUserStore } from "@/store/userStore";
import { auth } from "@/services/firebase";

export default {
  data() {
    return {
      userStore: useUserStore(),
      message: "",
      email: "",
    };
  },
  components: {
    BaseButton,
  },
  methods: {
    async sendMessage() {
      const token = await auth.currentUser.getIdToken(true);

      if (!this.message.trim()) return;

      try {
        const response = await fetch(`${process.env.VUE_APP_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ message: this.message, email: this.email }),
        });

        if (response.ok) {
          alert("Message sent successfully! Thanks for contacting us!");
          this.message = "";
          this.$emit("close");
        } else {
          alert("Failed to send message.");
        }
      } catch (error) {
        console.error("Error sending message:", error);
        alert("An error occurred. Please try again.");
      }
    },
  },
};
</script>
<style scoped>
.form-btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.message-form {
  position: fixed;
  top: 80px;
  left: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.form-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
input {
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
  width: 270px;
  border-style: solid;
}
textarea {
  border-radius: 10px;

  padding: 10px;
  width: 270px;
  height: 80px;
}

button {
  display: block;
  margin-top: 5px;
}
</style>
