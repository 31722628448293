<template>
  <div>
    <div class="container">
      <div class="closed-container">
        <!-- <div class="sub-cont-title">&nbsp;Selected&nbsp;</div> -->
        <base-button class="closed-box" @click="isOpened = true">
          <strong>{{ mytitle }}</strong>
        </base-button>

        <transition-group name="pop" tag="div" class="tags-container">
          <div
            v-for="(option, index) in selectedTags"
            :key="option"
            class="sel-tag"
          >
            <span
              @click="
                invalid - selection;
                selectedTags.splice(index, 1);
                $emit('selected-options', selectedTags);
              "
              class="text-small"
              style="cursor: pointer"
              >❌</span
            ><span class="text-small">{{ option }}</span>
          </div>
        </transition-group>
      </div>
    </div>

    <panel-message
      v-if="isOpened"
      @close="isOpened = false"
      :close-on-click-out="true"
      :backButton="true"
    >
      <div class="modal-content">
        <input
          type="text"
          v-model="searchText"
          @input="filterOptions"
          @keyup.enter="selectOption(searchText)"
          placeholder="Type ..."
          class="search-input"
        />
        <div v-if="outputMessage != ''" v-html="outputMessage" />
        <div
          class="sub-container sel-tag-container"
          v-if="selectedTags.length > 0"
        >
          <div class="sub-cont-title">&nbsp;Selected&nbsp;</div>
          <transition-group name="pop" tag="div" class="tags-container">
            <div
              v-for="(option, index) in selectedTags"
              :key="option"
              class="sel-tag"
            >
              <span
                @click="
                  selectedTags.splice(index, 1);
                  $emit('selected-options', selectedTags);
                "
                class="text-small"
                style="cursor: pointer"
                >❌</span
              ><span class="text-small">{{ option }}</span>
            </div>
          </transition-group>
        </div>
        <base-button
          class="btn-next"
          icon="fa-solid fa-check"
          icon-position="left"
          @click="
            $emit('selected-options', selectedTags);
            isOpened = false;
          "
          :when-to-shrink="0"
          >&nbsp;&nbsp;Confirm</base-button
        >
        <div class="tag-container">
          <base-button
            v-for="option in options"
            :key="option.name"
            :small-button="true"
            :class="{
              tag: true,
              highlighted: isHighlighted(option),
              notValid: !option.is_valid,
            }"
            @click="selectOption(option.name, true)"
          >
            {{ option.name }}
          </base-button>
        </div>
      </div>
    </panel-message>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import { useUserStore } from "@/store/userStore"; // Import your Pinia store

import PanelMessage from "./PanelMessage.vue";
export default {
  components: {
    PanelMessage,
    BaseButton,
  },
  props: {
    max_tags: {
      type: Number,
      default: 3,
    },
    title: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    can_type_their_own: {
      type: Boolean,
      required: false,
      default: false,
    },
    startingOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      userStore: useUserStore(),
      mytitle: this.title,
      max_n_tags: this.max_tags,
      canTypeTheirOwn: this.can_type_their_own,
      searchText: "",
      isOpened: false,
      outputMessage: "",
      selectedTags: this.startingOptions,
    };
  },
  methods: {
    isHighlighted(option) {
      return (
        this.searchText.trim().length > 4 &&
        this.searchText.trim() != "" &&
        option.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    selectOption(name) {
      // Normalize the input name by trimming whitespace and converting to lowercase.
      const normalizedInput = name.trim().toLowerCase();

      // Find the option that matches the normalized input.
      const foundOption = this.options.find(
        (option) => option.name.toLowerCase().trim() === normalizedInput
      );

      // Check if the name is found within the options and either the user is subscribed or the tag click originated.
      if (!foundOption && !this.canTypeTheirOwn) {
        this.$emit("invalid-selection", name);
        this.searchText = "";
        return;
      }
      if (foundOption && !foundOption.is_valid) {
        this.$emit("invalid-selection", name);
        this.searchText = "";
        return;
      }
      //   if (!this.userStore.isSubscribed && !foundOption.is_valid) {
      //     this.$emit("invalid-selection", name);
      //     this.searchText = "";

      //     return;
      //   }
      if (this.selectedTags.length >= this.max_n_tags) {
        this.outputMessage = `<div style='color:red'>Max ${this.max_n_tags} selections!</div>`;
        setTimeout(() => {
          this.outputMessage = "";
        }, 3000);
        return;
      }

      if (foundOption) {
        name = foundOption.name;
      }
      if (!this.selectedTags.includes(name) || name == "Random") {
        // Add the exact name from options to selectedTags to maintain any original casing or spacing.
        this.selectedTags.push(name);
        this.$emit("selected-options", this.selectedTags);

        this.searchText = "";
      }
    },
    closeIfClickedOutside(event) {
      if (event.target.classList.contains("standard-screen")) {
        this.$emit("close");
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.closed-container {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.standard-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}
.sel-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: transform 0.3s ease, opacity 0.5s ease-in-out;
}

.sel-tag {
  border-radius: 20px;
  border: 2px solid #2c3e508d;
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* transition: transform 0.3s ease; */
  transition: transform 0.5s, opacity 0.5s ease-in-out;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: white;
  padding: 20px;
}
.search-input {
  /* width: 100%; */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  align-self: stretch;
}

.notValid {
  opacity: 0.5;
}

.sel-tag:hover {
  transform: scale(1.05);
}
.highlighted ::v-deep .base-button {
  border: 4px solid black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.tags-container {
  display: flex; /* Adjust layout as necessary */
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.pop-move {
  transition: transform 1s ease;
}
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    opacity 0.3s ease-in-out;
}
.pop-enter-from,
.pop-leave-to {
  transform: scale(0);
  opacity: 0;
}
.pop-enter-to,
.pop-leave-from {
  transform: scale(1.1);
  opacity: 1;
}
.pop-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease-in-out;
}

input {
  min-width: 50px;
  padding-top: clamp(0rem, 1.5dvh, 5rem);
  padding-right: clamp(0rem, 2.5dvw, 5rem);
  padding-bottom: clamp(0rem, 2dvh, 5rem);
  padding-left: clamp(15px, 1dvw, 5rem);
  border: 2px solid transparent;
  border-radius: 20px;
}
</style>
