// src/stores/user.js
import { defineStore } from "pinia";
import { signOut } from "firebase/auth";
// import { useSharedInfoStore } from "@/store/sharedInfoStore";
import { auth } from "@/services/firebase";
import config from "@/assets/shared-config.json";
export const useUserStore = defineStore("user", {
  state: () => ({
    userRecord: {},
    saved_ai_personas: [],
    locFreeSecsLeft: 0,
    locPaidSecsLeft: 0,
    websocket: null,
    authReadyFromServer: false,
    userSessionAlready: false,
    volumePerc: 50,
    savedConvo: null,
    current_agent_id: null,
    ai_bio_info: null,
    ai_custom_instructions_info: null,
    current_agent_images: null,
    current_agent_chat_name: "",
    timer_speech_bubble_custom_ai: null,
    categories_unlcoked: null,
    options_unlocked: null,
  }),
  methods: {},
  getters: {
    currentIsSaved: (state) => {
      return state.saved_ai_personas?.some(
        (item) => item.persona_id === state.current_agent_id
      );
    },
    maxNewAIdaily: (state) => state.userRecord?.maxNewAIdaily ?? null,

    getGracePeriodEnds: (state) => state.userRecord?.gracePeriodEndsOn ?? null,
    getNumAIgenerated: (state) => state.userRecord?.numAIgenerated ?? 0,
    getEmail: (state) => state.userRecord?.email ?? "",
    getTimeWhenSharedFB: (state) =>
      state.userRecord?.getTimeWhenSharedFB ?? false,
    getTimeWhenSharedX: (state) =>
      state.userRecord?.getTimeWhenSharedX ?? false,
    displayName: (state) => state.userRecord?.displayName ?? "",
    isAnonymous: (state) => state.userRecord?.isAnonymous ?? true,
    isSubscribed: (state) => state.userRecord?.isSubscribed ?? false,
    unlockedAll: (state) => state.userRecord?.unlockedAll ?? false,
    unlockedOptions: (state) => state.userRecord?.unlockedOptions ?? [],
    user_uid: (state) => state.userRecord?.uid ?? null,
    totSecondsLeft: function () {
      return this.locFreeSecsLeft + this.locPaidSecsLeft;
    },
  },

  actions: {
    updateUnlockStatus() {
      const checkOptionUnlocked = (name_option) => {
        return (
          this.isSubscribed ||
          this.unlockedAll ||
          (this.unlockedOptions &&
            this.unlockedOptions.includes(config.categories[name_option]))
        );
      };
      const checkCategoryUnlocked = (categoryName) => {
        return (
          this.isSubscribed ||
          this.unlockedAll ||
          (this.unlockedOptions && this.unlockedOptions.includes(categoryName))
        );
      };

      // Extract unique categories from config.categories
      const uniqueCategories = [...new Set(Object.values(config.categories))];

      // Create an object where each category is mapped to true/false (unlocked or not)
      this.categories_unlocked = uniqueCategories.reduce(
        (acc, categoryName) => {
          acc[categoryName] = checkCategoryUnlocked(categoryName);
          return acc;
        },
        {}
      );
      this.options_unlocked = Object.keys(config.defaults).reduce(
        (acc, name_option) => {
          acc[name_option] = checkOptionUnlocked(name_option);
          return acc;
        },
        {}
      );
    },
    async fetchSavedPersonas() {
      if (this.isAnonymous) {
        return null;
      }
      this.nicknames = []; // Reset before fetching new data
      const token = await auth.currentUser.getIdToken(true);

      const response = await fetch(
        `${process.env.VUE_APP_URL}/api/get_saved_personas`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      this.saved_ai_personas = await response.json();
    },
    incrementNumAIgenerated() {
      if (this.userRecord) {
        this.userRecord.numAIgenerated += 1;
      }
    },
    sendPing() {
      // Ensure the socket is open before sending a message
      if (this.websocket.readyState === WebSocket.OPEN) {
        this.safeSend({ type: "ping" });
      }
    },
    safeSend(message) {
      if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.send(JSON.stringify(message));
      } else {
        console.warn("WebSocket is not open. Message not sent:", message);
      }
    },

    decrementFreeSeconds() {
      if (this.locFreeSecsLeft > 0) {
        this.locFreeSecsLeft--;
      }
    },
    decrementPaidSeconds() {
      if (this.locPaidSecsLeft > 0) {
        this.locPaidSecsLeft--;
      }
    },
    decrementTime() {
      if (this.locFreeSecsLeft > 0) {
        this.decrementFreeSeconds();
      } else {
        this.decrementPaidSeconds();
      }
    },

    setLocSecsLeft(freeSeconds, paidSeconds) {
      this.locFreeSecsLeft = freeSeconds;
      this.locPaidSecsLeft = paidSeconds;
    },

    updateUserRecord(record) {
      if (!this.userRecord) {
        this.userRecord = {};
      }
      for (const [key, value] of Object.entries(record)) {
        this.userRecord[key] = value;
      }
    },

    // async initializeAuth() {
    //   return new Promise((resolve) => {
    //     onAuthStateChanged(auth, async (user) => {
    //       if (user) {
    //         this.currentAuthUser = auth.currentUser;
    //         resolve(this.currentAuthUser);
    //       } else {
    //         await signInAnonymously(auth);
    //       }
    //     });
    //   });
    // },

    // // }
    // async signInAnonymously() {
    //   try {
    //     const result = await signInAnonymously(auth);
    //     await this.setAuthUser(result.user); // Make sure this method is suitable for async operations
    //     await this.createAnonymousUserRecord(result.user);
    //   } catch (error) {
    //     console.error("Anonymous sign-in failed:", error);
    //   }
    // },

    // Logout Function
    async logout() {
      //   this.currentAuthUser = null;
      this.idToken = null;
      await signOut(auth); // This will trigger onAuthStateChanged and sign in anonymously
    },
  },
});
