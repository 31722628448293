<template>
  <panel-message @close="$emit('close')" :close-on-click-out="true">
    <div class="select-and-text">
      <slot></slot>
      <div class="paying-options">
        <div class="sub-container buymin-cont">
          <div class="sub-cont-title text-mid-big">🪙One Time Payment🪙</div>
          <div class="text-small">
            <div>
              <strong>Your minutes only count down while chatting!</strong>
              <br />Your minutes never expire and can be used anytime! <br />
              (this is NOT a subscription!)
            </div>
            <!-- <div class="text-very-small">
            <strong
              >THIS IS <span style="color: red">NOT</span> A
              SUBSCRIPTION!</strong
            >
          </div> -->
          </div>
          <div class="buymin-btns">
            <base-button
              class="spend onebtn"
              v-for="option in options"
              :key="option.id"
              :action="
                () => redirectToCheckout({ type: 'buy_time', id: option.id })
              "
            >
              <span class="emoji">{{ option.emoji }}</span>
              <span class="buy-text">
                <span class="paid-seconds-left">${{ option.price_str }}</span> =
                <span class="buy-only-string" v-html="option.time_str"></span
              ></span>
            </base-button>
          </div>
        </div>
        <strong class="text-mid-big">OR</strong>
        <subscription-container />
      </div>
      <p>
        <i class="text-very-small">
          All payments are securely processed through
          <strong
            ><a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
              >Stripe</a
            ></strong
          >, a trusted and industry-leading payment provider</i
        >
      </p>
    </div>
  </panel-message>
</template>

<script>
import SubscriptionContainer from "@/components/paying_components/SubscriptionContainer.vue";
import PanelMessage from "@/components/PanelMessage.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useSharedInfoStore } from "@/store/sharedInfoStore";
import { computed } from "vue";
import { redirectToCheckout } from "@/services/userService.js";
export default {
  components: {
    BaseButton,
    PanelMessage,
    SubscriptionContainer,
  },
  setup() {
    const sharedInfoStore = useSharedInfoStore();
    sharedInfoStore.fetchPrices();

    // Populate options directly when sharedInfo is available

    const options = computed(() => {
      const paymentOptions = sharedInfoStore.prices?.buy_time_options || {};
      return Object.keys(paymentOptions).map((key) => {
        const option = paymentOptions[key];
        return {
          id: key,
          price_str: (option.price / 100).toFixed(2), // Convert cents to USD
          emoji: option.emoji,
          time_str: `${option.time_str}`,
        };
      });
    });
    return {
      options,
    };
  },

  methods: {
    redirectToCheckout,
    // redirectToCheckout(option) {
    //   redirectToCheckout(option);
    // },
    goBack() {
      this.$emit("close");
    },

    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.buymin-btns {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.paying-options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
}

.select-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: clamp(2px, 3dvh, 30px);

  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  .paying-options {
    flex-wrap: wrap;
  }
}

.text-big-sub {
  font-size: clamp(1.5rem, 3.5dvw, 1.5rem);
}
.emoji {
  font-size: 1.2em;
  vertical-align: bottom;
  line-height: text-top;
}

.onebtn {
  flex-grow: 1;
}

.sub-container {
  flex: 1;
  padding-bottom: 20px;
}

</style>
