import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC_zq593tUALd9tJd006gewz70df6BKusM",
  authDomain: "omegleai.firebaseapp.com",
  projectId: "omegleai",
  storageBucket: "omegleai.appspot.com",
  messagingSenderId: "902887279759",
  appId: "1:902887279759:web:32d241edc7e983b42974fa",
  measurementId: "G-VBSWGSWG8S",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);

const auth = getAuth(app);

// Export Firebase services
export { db, auth };
