<template>
  <panel-message
    :back-button="true"
    @close="
      emitClose();
      showCustomInstructions = false;
    "
  >
    <div class="container">
      <hr-section>&nbsp;Custom Instructions</hr-section>
      <div class="cool-text">
        This is an incredibly powerful feature! Use it wisely!
      </div>
      <div>
        Your instructions will be added to all your other customizations.<br />Make
        sure everything works well together!
      </div>
      <div>
        You will be able to modify the Custom Instructions at any time, even
        while talking with the AI!
      </div>
      <div>
        <strong>Add whatever you want.</strong><br />
        Examples:<br />
        Who are you? 🤔<br />
        How do you know each other? 🤝<br />
        What is the nature of your relationship? 🫶
      </div>
      <div class="form-group">
        <div class="textbox">
          <textarea
            id="description"
            name="description"
            class="text-small"
            placeholder="Write in the first person as yourself, for example: 'My name is... I am... We know each other from... You are..."
            v-model="selectedInstructionSlot.description"
            :maxlength="maxLength"
          ></textarea>
          <div class="char-left text-small">{{ charactersLeft }}</div>
        </div>
      </div>
      <div>
        <strong
          >These custom instructions will only apply to new AI
          companions.</strong
        >
      </div>
      <base-button
        :icon="'fa-solid fa-floppy-disk'"
        :icon-position="'left'"
        :action="saveCustomInstructions"
        :when-to-shrink="0"
        class="save"
        >&nbsp;&nbsp;Save</base-button
      >
      <div v-if="thisOutputMessage != ''" v-html="thisOutputMessage"></div>
    </div>
  </panel-message>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import PanelMessage from "./PanelMessage.vue";

import HrSection from "./HrSection.vue";
import { useUserStore } from "@/store/userStore";

export default {
  computed: {
    charactersLeft() {
      return this.maxLength - this.selectedInstructionSlot.description.length;
    },
    selectedInstructionSlot() {
      return this.localCopyInstructions.find(
        (option) => option.name == this.firstSelectedName
      );
    },
  },
  components: {
    BaseButton,
    HrSection,
    PanelMessage,
  },
  props: {
    allInstructions: { type: Array, required: true },
    firstSelectedName: { type: String, default: "Custom" },
  },
  data() {
    return {
      selected: this.firstSelectedName,
      ai_id: this.selectedAiId,
      localCopyInstructions: JSON.parse(JSON.stringify(this.allInstructions)),
      userStore: useUserStore(),
      thisOutputMessage: "",
      maxLength: 400,
    };
  },

  methods: {
    emitClose() {
      this.$emit("close");
    },
    async saveCustomInstructions() {
      this.$emit("save", this.localCopyInstructions);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.save-ai-btn {
  z-index: 5;
}

.textbox {
  position: relative;
}

.char-left {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.container {
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 1.5dvw, 20px);

  width: 100%;
}

.save {
  margin-top: 10px;
}
.save,
.close {
  align-self: center;
}

.form-container {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
label {
  display: block;
  margin-bottom: 5px;
}
.cool-text {
  color: red;
  text-shadow: 0 0 1px #ff0000;
  font-weight: bold;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
textarea {
  height: 100px;
}
</style>
