<template>
  <panel-message :close-on-click-out="true">
    <div class="pp">
      <h1>Terms of Service</h1>
      <p><strong>Last updated: July 30, 2024</strong></p>

      <p>Welcome to {{ domainInfo.name }}!</p>

      <p>
        These Terms of Service ("Terms") govern your access to and use of the
        {{ domainInfo.name }} website and services, operated by Valerio
        Biscione. By accessing or using {{ domainInfo.name }} (the "Service"),
        you agree to comply with and be bound by these Terms. If you do not
        agree with these Terms, please do not use the Service.
      </p>

      <div class="section">
        <h2>1. Use of the Service</h2>
        <p>
          To use {{ domainInfo.name }}, you must create an account via Google
          authentication or similar systems. Your account is personal and
          non-transferable. You are responsible for maintaining the
          confidentiality of your account and any activities that occur under
          it.
        </p>
      </div>

      <div class="section">
        <h2>2. User Content</h2>
        <p>
          {{ domainInfo.name }} is a chat service powered by AI. Users can post
          chat messages but cannot upload images or other content. The content
          of the chat sessions remains the property of the user, and we do not
          claim ownership of your messages.
        </p>
      </div>

      <div class="section">
        <h2>3. Intellectual Property</h2>
        <p>
          All content, trademarks, and other intellectual property rights
          associated with {{ domainInfo.name }} are owned by Valerio Biscione or
          its licensors. You may not use, reproduce, or distribute any content
          from the Service without our express written permission.
        </p>
      </div>

      <div class="section">
        <h2>4. Limitation of Liability</h2>
        <p>
          {{ domainInfo.name }} is provided "as is" and "as available" without
          warranties of any kind. Valerio Biscione will not be liable for any
          damages arising from your use of the Service, including but not
          limited to indirect, incidental, or consequential damages.
        </p>
      </div>

      <div class="section">
        <h2>5. Changes to the Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes via email to the address associated with your
          account. Continued use of the Service after any changes constitutes
          your acceptance of the revised Terms.
        </p>
      </div>

      <div class="section">
        <h2>6. Governing Law</h2>
        <p>
          These Terms are governed by the laws of Italy. Any disputes arising
          from or related to these Terms or the Service will be subject to the
          exclusive jurisdiction of the courts located in Italy.
        </p>
      </div>

      <div class="section">
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
          <a :href="`mailto:${domainInfo.email}`">{{ domainInfo.email }}</a
          >.
        </p>
      </div>

      <p>Thank you for using {{ domainInfo.name }}!</p>
    </div>
    <base-button
      @click="close"
      class="close"
      :icon="'fa-solid fa-arrow-left'"
      :icon-position="'left'"
      :when-to-shrink="0"
      i
      >&nbsp;&nbsp;Back</base-button
    >
  </panel-message>
</template>

<script>
import PanelMessage from "./PanelMessage.vue";
import BaseButton from "./BaseButton.vue";

export default {
  props: ["isVisible"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  components: {
    BaseButton,
    PanelMessage,
  },
};
</script>

<style scoped>
p,
li {
  text-align: justify;
}

p,
li {
  font-size: 1rem;
}
</style>
