<template>
  <div class="title-section">
    <hr class="hr" />
    <div class="title">&nbsp;<slot></slot>&nbsp;</div>
  </div>
</template>

<style scoped>
.title-section {
  margin-top: 15px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hr {
  position: absolute;
  width: 100%;
  height: 2px;
  border: 0px;
  border-radius: 5%;
  background-color: #f3df00;
}

.title {
  /* color: green; */
  position: absolute;
  top: -0.8em;
  background-color: white;
  font-weight: bold;
  white-space: nowrap;
}
</style>
