<template>
  <div>
    <sign-in-welcome
      :close-on-click-out="true"
      class="welcome-panel"
      @close="this.$emit('close')"
      v-if="showWelcomeScreen"
    />
    <panel-message
      v-show="!showWelcomeScreen"
      class="panel"
      :back-button="true"
      @close="$emit('close')"
      :close-on-click-out="true"
      ><div v-html="titleMessage" v-if="titleMessage != ''"></div>
      <p class="text-mid-big"><strong>Sign Up Now! 🤗</strong></p>
      <!-- 🗯️ -->
      <ul class="additional-info-sign-up">
        <li>
          Customize your AI companion! Choose from many exciting features ❤️
        </li>
        <!-- <li>Enjoy Free Chat Every Day! 🙌</li> -->
        <li>
          Found someone interesting? Add them to your friends list and chat with
          them whenever you want! 💾
        </li>
        <li>AIs will remember current and past chats! 🧠</li>
        <li>Share AIs with your friends! 🌈</li>
      </ul>

      <!-- <p>(It's free!😙)</p> -->
      <base-button
        :action="signInG"
        class="sign-in spend google-sign-in-btn"
        :icon="'fa-brands fa-google'"
        :icon-position="'left'"
        :when-to-shrink="0"
        ><span class="provider-text">Continue with Google</span>
      </base-button>
      <base-button
        v-if="!promptSignupGoogle"
        :action="signInWithTwitter"
        class="sign-in spend google-sign-in-btn"
        :icon="'fa-brands fa-twitter'"
        :icon-position="'left'"
        :when-to-shrink="0"
        ><span class="provider-text">Continue with Twitter</span>
      </base-button>
      <p style="color: red" v-if="promptSignupGoogle">
        An account with email: {{ email }} already exists. <br />Please sign in
        with Google 💪
      </p>
      <span style="color: red"
        >Like you, we hate spam too. You will <b>never</b> receive spam emails
        from us.</span
      >
      <span class="terms-and-pp-text"
        >By continuing, you agree to our
        <span @click="showTerms = true" class="privacy-policy-link">Terms</span>
        and acknowledge our
        <span @click="showPP = true" class="privacy-policy-link"
          >Privacy Policy</span
        >
        <br />All chats are completely private and secure. We do not access or
        monitor them. Go wild! 😜
      </span>
      <div v-if="message != ''" v-html="message"></div>
      <!-- <base-button
        @click="closePanel"
        class="close"
        :icon="'fa-solid fa-arrow-left'"
        :icon-position="'left'"
        :when-to-shrink="0"
        i
        >&nbsp;&nbsp;Back To Chat</base-button
      > -->
      <privacy-policy v-if="showPP" @close="showPP = false"></privacy-policy>
      <terms-service
        v-if="showTerms"
        @close="showTerms = false"
      ></terms-service>
    </panel-message>
  </div>
</template>

<script>
import signInWelcome from "@/components/SignInWelcome.vue";
import PanelMessage from "@/components//PanelMessage.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useUserStore } from "@/store/userStore";
import { signInWithGoogle } from "@/services/userService";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import TermsService from "./TermsService.vue";
import { TwitterAuthProvider } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { auth } from "@/services/firebase"; // Adjust path as necessary
export default {
  data() {
    return {
      userStore: useUserStore(),
      showPP: false,
      showTerms: false,
      message: "",
      titleMessage: this.title_message,
      showWelcomeScreen: false,
      promptSignupGoogle: false,
      email: "",
    };
  },
  components: {
    BaseButton,
    PanelMessage,
    PrivacyPolicy,
    signInWelcome,
    TermsService,
  },
  props: {
    title_message: { type: String, default: "" },
  },
  methods: {
    async signInG() {
      this.$gtag.event("sign_up", {
        method: "Google",
      });

      const result = await signInWithGoogle();
      if (!result) {
        this.message =
          "<style='color:red'>❌Sign up Failed!❌</style><br>If you keep having trouble signing up, please contact us on Discord!";
        setTimeout(() => {
          this.message = "";
        }, 10000);
      } else {
        this.showWelcomeScreen = true;
      }
    },
    async signInWithTwitter() {
      const provider = new TwitterAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        console.log("Sign-in with Twitter was successful.");
        console.log(`User's email: ${result.user.email}`);
        this.showWelcomeScreen = true;
        //  const additionalUserInfo = result.additionalUserInfo;
        // const profile = additionalUserInfo.profile;
      } catch (error) {
        console.error("Error during Twitter sign-in:", error.message);
        // TODO: This part currently doesn't work because the fetchSignInMethodsForEmail returns an empty list
        if (error.code === "auth/account-exists-with-different-credential") {
          this.promptSignupGoogle = true;
          this.email = error.customData.email;
        } else {
          this.message =
            "<style='color:red'>❌Sign up Failed!❌</style><br>If you keep having trouble signing up, please contact us on Discord!";
          setTimeout(() => {
            this.message = "";
          }, 10000);
        }
      }
    },
  },
};
// p
</script>

<style scoped>
::v-deep .standard-content {
  --max-width: 50dvw;
}
.provider-text {
  margin-left: 20px;
}

.privacy-policy-link {
  color: rgb(39, 39, 47);
  cursor: pointer;
  text-decoration: underline;
}

.privacy-policy-link:hover {
  text-decoration: none;
}

.terms-and-pp-text {
  font-size: clamp(0.7rem, 1dvw, 1rem);
}

.terms-and-pp-text span {
  font-size: clamp(0.7rem, 1dvw, 1rem);
}
.additional-info-sign-up {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
