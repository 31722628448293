<template>
  <div id="app">
    <speech-bubble ref="speechBubble" />
    <router-view />
  </div>
</template>

<script>
// import { useUserStore } from "@/store/userStore";
import SpeechBubble from "./components/SpeechBubble.vue";
import { enable as enableDarkMode } from "darkreader";
import { getItemWithDefault } from "@/services/userService";

export default {
  name: "App",
  components: {
    SpeechBubble,
  },
  methods: {},
  mounted() {
    if (getItemWithDefault("darkMode", "false") == "true") {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    }
  },
};
</script>

<style>
@import "./styles/spinner.css";

#app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  text-align: center;
  gap: clamp(0px, 0.5dvh, 5px);
  color: #2c3e50;
}
</style>
