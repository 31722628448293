<template>
  <panel-message :close-on-click-out="true">
    <div class="pp">
      <h1>Privacy Policy</h1>
      <p><strong>Last updated: July 30, 2024</strong></p>

      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use, and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal Data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the
        <a
          href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
          target="_blank"
          >Free Privacy Policy Generator</a
        >.
      </p>

      <div class="section">
        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by, or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest, or other securities entitled to vote for the election of
            directors or other managing authority.
          </li>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us", or "Our" in this Agreement) refers to {{ domainInfo.name }}.
          </li>
          <li>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device, or any other device by a website,
            containing the details of Your browsing history on that website
            among its many uses.
          </li>
          <li><strong>Country</strong> refers to Italy.</li>
          <li>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone, or a digital tablet.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li><strong>Service</strong> refers to the Website.</li>
          <li>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service, or to assist
            the Company in analyzing how the Service is used.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (e.g., the duration of a page visit).
          </li>
          <li>
            <strong>Website</strong> refers to {{ domainInfo.name }}, accessible
            from
            <a :href="domainInfo.url" target="_blank">{{ domainInfo.url }}</a>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </div>

      <div class="section">
        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>
          <strong>Chat Data:</strong> We temporarily store the content of your
          chats to provide our services. However, all chat data is automatically
          deleted from our systems within one hour following the end of your
          session.
        </p>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g., IP address), browser type, browser version,
          the pages of our Service that You visit, the time and date of Your
          visit, the time spent on those pages, unique device identifiers, and
          other diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device
          identifiers, and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <h4>Tracking Technologies and Cookies</h4>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (e.g., recording the popularity of a certain section and
            verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. Learn more about cookies on the
          <a
            href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
            target="_blank"
            >Free Privacy Policy website</a
          >
          article.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>
            <p><strong>Necessary / Essential Cookies</strong></p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <p><strong>Functionality Cookies</strong></p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <h2>Data Protection Rights</h2>
        <p>
          Under the General Data Protection Regulation (GDPR), you have the
          following rights regarding your personal data:
        </p>
        <ul>
          <li>
            <strong>Right to Access:</strong> You have the right to request a
            copy of the personal data we hold about you and to verify that we
            are processing it lawfully.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You have the right to
            request that we correct or update any inaccurate or incomplete
            personal data.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You have the right to request the
            deletion of your personal data, subject to certain exceptions (e.g.,
            if the data is necessary for compliance with a legal obligation).
          </li>
          <li>
            <strong>Right to Restriction of Processing:</strong> You have the
            right to request that we restrict the processing of your personal
            data under certain conditions, such as if you contest the accuracy
            of the data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You have the right to
            request a copy of your personal data in a structured, commonly used,
            and machine-readable format, and to transfer it to another data
            controller.
          </li>
          <li>
            <strong>Right to Object:</strong> You have the right to object to
            the processing of your personal data based on legitimate interests
            or for direct marketing purposes.
          </li>
          <li>
            <strong>Right to Withdraw Consent:</strong> If the processing of
            your personal data is based on your consent, you have the right to
            withdraw your consent at any time without affecting the lawfulness
            of processing based on consent before its withdrawal.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the contact
          information provided in the "Contact Us" section. We will respond to
          your request within the timeframe stipulated by applicable data
          protection laws.
        </p>
        <h2>International Data Transfers</h2>
        <p>
          If we transfer your personal data outside of the European Economic
          Area (EEA), we will ensure that appropriate safeguards are in place to
          protect your data in accordance with GDPR requirements. This may
          include using Standard Contractual Clauses approved by the European
          Commission or ensuring that the recipient is part of the EU-U.S.
          Privacy Shield framework, where applicable.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, your data
          protection rights, or if you wish to exercise any of your rights,
          please contact us:
        </p>
        <ul>
          <li>
            By email:
            <a :href="`mailto:${domainInfo.email}`">{{ domainInfo.email }}</a>
          </li>
        </ul>
      </div>
    </div>
    <base-button
      @click="close"
      class="close"
      :icon="'fa-solid fa-arrow-left'"
      :icon-position="'left'"
      :when-to-shrink="0"
      i
      >&nbsp;&nbsp;Back</base-button
    >
  </panel-message>
</template>

<script>
import PanelMessage from "./PanelMessage.vue";
import BaseButton from "./BaseButton.vue";

export default {
  props: ["isVisible"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  components: {
    BaseButton,
    PanelMessage,
  },
};
</script>

<style scoped>
p,
li {
  text-align: justify;
}

p,
li {
  font-size: 1rem;
}
</style>
